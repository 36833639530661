<template>
  <div class="wrap">
    <!-- 搜索 -->
    <a-form layout="inline">
      <a-form-item label="操作者">
        <a-input v-model:value.trim="operatorName" placeholder="请输入任务名称" size="small" allowClear></a-input>
      </a-form-item>
      <!-- <a-form-item label="操作类型">
        <a-select v-model:value="operateType" size="small" allowClear>
          <a-select-option v-for="(item, index) in operateTypeList" :key="index" :value="index">
            {{ item }}
          </a-select-option>
        </a-select>
      </a-form-item> -->
      <a-form-item label="发布时间">
        <a-date-picker
          size="small"
          v-model:value="startTime"
          :disabled-date="disabledStartDate"
          show-time
          valueFormat="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD HH:mm:ss"
          placeholder="开始时间"
        />
      </a-form-item>
      <a-form-item label="结束时间">
        <a-date-picker
          size="small"
          v-model:value="endTime"
          :disabled-date="disabledEndDate"
          show-time
          valueFormat="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD HH:mm:ss"
          placeholder="结束时间"
        />
      </a-form-item>

      <a-form-item class="btn-group">
        <a-button type="primary" size="small" @click="search">查询</a-button>
        <a-button size="small" @click="reset">重置</a-button>
      </a-form-item>
    </a-form>
    <div class="operate-type">
      <a-radio-group :default-value="0" v-model="operateType" @change="handleChange">
        <a-radio-button :value="0">
          查询日志
        </a-radio-button>
        <a-radio-button :value="1">
          错误日志
        </a-radio-button>
        <a-radio-button :value="2">
          操作日志
        </a-radio-button>
      </a-radio-group>
    </div>
    <!-- 表格 -->
    <a-table
      :dataSource="dataSource"
      :columns="columns"
      :pagination="{ pageSize: pageSize, current: curPage, total: total }"
      :row-key="record => record.id"
      @change="handlePageChange"
    >
      <template #idx="row">
        {{ (curPage - 1) * pageSize + row.index + 1 }}
      </template>
    </a-table>
  </div>
</template>

<script>
import { defineComponent, toRefs, reactive, onMounted } from 'vue';
import api from '@/services';

const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    align: 'center',
    width: 80,
    slots: {
      customRender: 'idx'
    }
  },
  {
    title: '登陆IP',
    dataIndex: 'operateIp',
    align: 'center',
    ellipsis: true
  },
  {
    title: '操作者',
    dataIndex: 'operatorName',
    align: 'center'
  },
  {
    title: '操作内容',
    dataIndex: 'operateContent',
    align: 'center'
  },
  {
    title: '操作时间',
    dataIndex: 'updateTime',
    align: 'center'
  },
  {
    title: '操作类型',
    dataIndex: 'type',
    align: 'center'
  },
  {
    title: '添加日期',
    dataIndex: 'createTime',
    align: 'center',
    ellipsis: true
  }
];



// 操作内容列表类型 list
const operateTypeList = ['登陆', '查询', '新增', '编辑', '删除', '直播发起', '发布视频', '错误日志'];
export default defineComponent({
  setup() {
    const formState = reactive({
      operatorName: '',
      operateType: 0,
      startTime: '',
      endTime: ''
    });

    onMounted(() => {
      getLogManageList();
    });

    // table 相关变量
    const tableState = reactive({
      pageSize: 5,
      curPage: 1,
      total: 0,
      dataSource: [],
      columns
    });

    // 获取日志列表 table data
    const getLogManageList = async () => {
      const params = {
        ...formState,
        pageSize: tableState.pageSize,
        curPage: tableState.curPage
      };
      delete params.rangeDate;

      const { data, success } = await api.getLogManageList(params);
      if (success) {
        tableState.dataSource = data.records;
        tableState.total = data.total;
      }
    };

    const handlePageChange = page => {
      tableState.curPage = page.current;
      getLogManageList();
    };

    const search = () => {
      tableState.curPage = 1;
      getLogManageList();
    };

    let columns_v = [...columns]
    let columns_v_ = [...columns]
    columns_v.splice(1,2)
    const handleChange = e => {
      if(e.target.value == 1) {
        tableState.columns = columns_v
      } else {
        tableState.columns = columns_v_
      }
      formState.operateType = e.target.value;
      search();
    };

    const reset = () => {
      Object.keys(formState).map(key => {
        if (key !== 'operateType') formState[key] = '';
      });
      search();
    };

    const disabledStartDate = startTime => {
      if (!formState.startTime || !formState.endTime) {
        return false;
      }

      return startTime.valueOf() > formState.endTime.valueOf();
    };

    const disabledEndDate = endTime => {
      if (!formState.endTime || !formState.startTime) {
        return false;
      }

      return formState.startTime.valueOf() >= endTime.valueOf();
    };

    return {
      operateTypeList,
      getLogManageList,
      handlePageChange,
      search,
      handleChange,
      reset,
      disabledStartDate,
      disabledEndDate,
      ...toRefs(formState),
      ...toRefs(tableState)
    };
  }
});
</script>

<style lang="less" scoped>
.wrap {
  padding: 15px 20px;
  flex-wrap: wrap;
  :deep(.ant-form) {
    display: flex;
    justify-content: center;
    margin: 15px 0;
    .ant-select {
      width: 100px;
    }
    .btn-group {
      flex: 1;
      .ant-btn {
        margin-left: 20px;
      }
    }
  }
}
</style>
